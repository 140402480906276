/* eslint-disable */
import { lazy } from 'react';
// import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';

const home = {
  home: lazy(() => import('views/dashboard/HomeDashboard')),
};

const courses = {
  explore: lazy(() => import('views/courses/CoursesExplore')),
  list: lazy(() => import('views/courses/CoursesList')),
  detail: lazy(() => import('views/courses/CoursesDetail')),
  lessons: lazy(() => import('views/courses/CourseLessons')),
};

const paths = {
  list: lazy(() => import('views/paths/PathsList')),
  detail: lazy(() => import('views/paths/PathsDetail')),
};

const instructor = {
  list: lazy(() => import('views/instructor/InstructorList')),
  detail: lazy(() => import('views/instructor/InstructorDetail')),
};
const misc = {
  player: lazy(() => import('views/misc/Player')),
  material: lazy(() => import('views/misc/Material')),
  syllabus: lazy(() => import('views/misc/Syllabus')),
  terms: lazy(() => import('views/misc/Terms')),
  about: lazy(() => import('views/misc/About')),
};

const login = { login: lazy(() => import('views/pages/authentication/Login')) };

const confirmEmail = { confirmEmail: lazy(() => import('views/misc/ConfirmEmail')) };

const register = { register: lazy(() => import('views/pages/authentication/Register')) };

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: `${appRoot}/users/dashboard/home`,
      icon: 'home-garage',
      label: 'menu.dashboard',
      exact: true,
      component: home.home
      
    },
    {
      path: `${appRoot}/users/courses/explore`,
      label: 'menu.courses',
      icon: 'online-class',
      exact: true,
      component: courses.explore
    },
    {
      path: `${appRoot}/users/courses/detail/:id`,
      exact: true,
      noLayout: false,
      component: courses.detail
    },
    {
      path: `${appRoot}/users/courses/detail/:id/lessons/:lessonId`,
      exact: true,
      noLayout: false,
      component: courses.lessons
    },

    {
      path: `${appRoot}/user-login`,
      exact: true,
      component: login.login,
      hideInMenu: true,
      publicOnly: false,
      noLayout: true,

    },
    {
      path: `${appRoot}/`,
      exact: true,
      component: login.login,
      hideInMenu: true,
      publicOnly: false,
      noLayout: true,

    },

    {
      path: `${appRoot}/user-register`,
      exact: true,
      component: register.register,
      hideInMenu: true,
      publicOnly: false,
      noLayout: true,
    },
    

    {
      path: `${appRoot}/about`,
      label: 'menu.about',
      icon: 'light-on',
      exact: true,
      component: misc.about
    },
    {
      path: `${appRoot}/terms`,
      noLayout: true,
      exact: true,
      component: misc.terms
    },

    {
      path: `${appRoot}/confirm-email`,
      noLayout: true,
      exact: true,
      component: confirmEmail.confirmEmail
    }

  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
